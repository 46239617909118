<template>
  <div class="ensemble-single" v-show="!isLoading">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">{{ ensemble.name }}</h1>
          <h2 class="subtitle">{{ ensemble.city }}, {{ ensemble.state }}</h2>
        </div>
      </div>
    </section>
    <section class="ensemble-content">
      <div class="container">
        <div class="columns">
          <div class="column">
            <img :src="ensemble.featuredImage" alt="Placeholder image" id="ensembleImage" />
          </div>
          <div class="column">
            <h2 class="subtitle is-3">Biography</h2>
            <p class="is-size-5 description">{{ ensemble.description }}</p>
            <div class="groupSocialMedia">
              <a target="_blank" rel="noopener noreferrer" :href="ensemble.facebookLink" v-if="ensemble.facebookLink">
                <img src="../img/fb.png"/>
              </a>
              <a target="_blank" rel="noopener noreferrer" :href="ensemble.websiteLink" v-if="ensemble.websiteLink">
                <img src="../img/web.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import EnsembleService from "../services/ensembleService";

export default {
  name: "EnsembleSingle",
  data() {
    // initialize the ensemble object
    return {
      isLoading: false,
      ensemble: {}
    };
  },
  async mounted(){
    this.ensemble = await EnsembleService.get(this.$route.params.id)
  }
};
</script>
<style lang="scss" scoped>
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 40px;
}
.hero.is-primary {
  background-color: transparent;
}
.ensemble-container {
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .description {
    margin: 10px;
  }
}
#ensembleImage {
  max-height: 450px;
  border-radius: 5px;
  filter: drop-shadow(0 0 0.25rem black);
}
.groupSocialMedia a img {
  max-height: 40px;
  margin: 5px;
}
</style>